import { toggleSpinner } from "./companyForm";
import { CopyToClipboard } from "./forms";
export class Tooltip {
    constructor(toggles) {
        this.toggleButtons = Array.from(toggles);

        this.toggleButtons.forEach((toggle) => {
            if (toggle) {
                const tipHeading = toggle.getAttribute('data-tooltip-heading') || "";
                const tipContent = toggle.getAttribute('data-tooltip-content') || "";
                const tipSystemPrompt = toggle.getAttribute('data-tooltip-system-prompt') || "";
                const tipUserPrompt = toggle.getAttribute('data-tooltip-user-prompt') || "";
                const tipElement = toggle.nextElementSibling;
                const tipAiButton = tipElement ? tipElement.querySelector(".tooltip__aicallbutton") : null;
                const tipAiRegenerateButton = tipElement ? tipElement.querySelector(".tooltip__airegenaratebutton") : null;
                const tipAiCopyAnswerTextareaButton = tipElement ? tipElement.querySelector(".tooltip__aicopyanswertextarea") : null;
                const tipAiCopyButton = tipElement ? tipElement.querySelector(".tooltip__aicopybutton") : null;

                // Add event listeners.
                toggle.addEventListener('click', (e) => this.clickHandler(e, tipElement));
                // Close the tooltip when pressing ESC button when the focus is on the tip button
                toggle.addEventListener('keydown', (e) => this.keyPressHandler(e, tipElement));
                // Close the tooltip when pressing ESC button when the focus is inside the tooltip content
                if (tipElement) {
                    tipElement.addEventListener('keydown', (e) => this.keyPressHandler(e, tipElement));
                }

                if (tipAiButton) {
                    tipAiButton.addEventListener('click', async (e) => await this.aiButtonClickHandler(e, toggle, tipElement, tipAiButton, tipAiRegenerateButton, tipAiCopyAnswerTextareaButton, tipAiCopyButton, tipSystemPrompt, tipUserPrompt));
                }

                if (tipAiRegenerateButton) {
                    tipAiRegenerateButton.addEventListener('click', async (e) => await this.aiButtonClickHandler(e, toggle, tipElement, tipAiButton, tipAiRegenerateButton, tipAiCopyAnswerTextareaButton, tipAiCopyButton, tipSystemPrompt, tipUserPrompt));
                }

                if (tipAiCopyAnswerTextareaButton) {
                    tipAiCopyAnswerTextareaButton.addEventListener('click', (e) => this.copyAiAnswerToTextArea(e, toggle, tipElement));
                }

                if (tipAiCopyButton) {
                    tipAiCopyButton.addEventListener('click', (e) => {
                        e.stopPropagation();
                        const aiAnswerCopy = this.getAiAnswer(tipElement);
                        if (aiAnswerCopy) {
                            CopyToClipboard(aiAnswerCopy, "The text is copied.");
                        }
                    });
                }

                document.addEventListener('click', (e) => this.outsideTipClickHandler(e, tipHeading, toggle, tipElement));
            }
        })
    }

    clickHandler(e, tip) {
        e.stopPropagation();

        // Toggle the currently clicked tip.
        if (tip) {
            this.toggleTip(tip);
        }

        const tipParentOpen = document.querySelectorAll('.tooltip__tip-container[aria-expanded="true"]');
        // If one tip is open only toggling the current tip is needed. That is handled by previous condition.
        // Only activate when more than one tip is open and then toggle the previous tip(s).
        if (tipParentOpen && tipParentOpen.length > 1) {
            for (var i = 0; i < tipParentOpen.length; i++) {
                const openTip = tipParentOpen[i];
                if (openTip !== tip) {
                    this.toggleTip(openTip);
                }
            }
        }
    }

    keyPressHandler(event, tip) {
        if (tip && event.key === 'Escape') {
            this.closeTip(tip);
        }
    }

    closeTip(tipElement) {
        if (tipElement && !tipElement.classList.contains("d-none")) {
            tipElement.classList.add("d-none");
            tipElement.setAttribute('aria-expanded', 'false');
        }
    }

    showElement(htmlElement) {
        if (htmlElement && !htmlElement.classList.contains("d-none")) {
            htmlElement.classList.add("d-none");
        }
    }

    toggleTip(tipElement) {
        if (tipElement) {
            tipElement.classList.toggle("d-none");
            const ariaExpandedAttribute = tipElement.getAttribute('aria-expanded');
            tipElement.setAttribute('aria-expanded', ariaExpandedAttribute === "true" ? "false" : "true");
        }
    }

    outsideTipClickHandler(e, tipHeading, toggle, tipElement) {
        e.stopPropagation();
        if (tipHeading && toggle && tipElement) {
            if (tipHeading === "TIPS" && e.target !== toggle && e.target !== toggle.children[0]) {
                this.closeTip(tipElement);
            } else if (tipHeading === "AI" && !tipElement.children[0].contains(e.target)) {
                this.closeTip(tipElement);
            }
        }
    }

    async openAiCall(openAiUrl, requestdata, tip, tipAiButton, tipAiRegenerateButton, tipAiCopyAnswerTextarea, tipAiCopyButton) {
        const tipJquery = $(tip);
        const that = this;
        try {
            $.ajax({
                url: openAiUrl,
                type: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                contentType: false,
                data: JSON.stringify(requestdata),
                processData: false,
                beforeSend: function () {
                    toggleSpinner(true, tipJquery);
                    that.toggleDisableAiButton(tipAiButton);
                    that.toggleDisableAiButton(tipAiRegenerateButton);
                }
            })
                .done(function (result) {
                    const aiContent = result.choices[0].message.content;
                    if (aiContent) {
                        const aiContentEl = tip.querySelector(".tooltip__aichatcontent");
                        const aiContentSuggested = tip.querySelector(".tooltip__aichatsuggested");

                        if (aiContentEl) {
                            aiContentEl.innerText = `"${aiContent}"`;
                        }

                        if (aiContentSuggested && aiContentSuggested.classList.contains("d-none")) {
                            aiContentSuggested.classList.remove("d-none");
                        }

                        if (tipAiRegenerateButton && tipAiRegenerateButton.classList.contains("d-none")) {
                            tipAiRegenerateButton.classList.remove("d-none");
                        }

                        if (tipAiCopyAnswerTextarea && tipAiCopyAnswerTextarea.classList.contains("d-none")) {
                            tipAiCopyAnswerTextarea.classList.remove("d-none");
                        }

                        if (tipAiCopyButton && tipAiCopyButton.classList.contains("d-none")) {
                            tipAiCopyButton.classList.remove("d-none");
                        }

                        if (tipAiButton && !tipAiButton.classList.contains("d-none")) {
                            tipAiButton.classList.add("d-none");
                        }
                    }
                })
                .fail(function () {
                    const aiContentEl = tip.querySelector(".aichatcontent");
                    if (aiContentEl) {
                        aiContentEl.innerText = "Something went wrong.";
                    }
                })
                .always(function () {
                    toggleSpinner(false, tipJquery);
                    that.toggleDisableAiButton(tipAiButton);
                    that.toggleDisableAiButton(tipAiRegenerateButton);
                });
        } catch (error) {
            const aiContentEl = tip.querySelector(".aichatcontent");
            if (aiContentEl) {
                aiContentEl.innerText = "Something went wrong.";
            }
        };
    }


    async aiButtonClickHandler(e, toggle, tipElement, tipAiButton, tipAiRegenerateButton, tipAiCopyAnswerTextarea, tipAiCopyButton, tipSystemPrompt, tipUserPrompt) {
        e.stopPropagation();
        const userContentEl = toggle.parentElement.querySelector('textarea, input[type="text"]');

        // approx 4 characters = 1 token. 150 tokens is approx 600 characters. This is the max length in company form text area.
        let maxTokens = 150;
        let userContent = null;

        if (userContentEl) {
            if (userContentEl.value) {
                userContent = userContentEl.value;
            }
            const userContentMaxLength = userContentEl.getAttribute("maxlength");
            if (userContentMaxLength) {
                maxTokens = Math.round(Number(userContentMaxLength) / 4);
            }
        }

        let aiMessages = [];

        if (tipSystemPrompt) {
            aiMessages.push({
                role: "system",
                content: tipSystemPrompt
            });
        }

        if (tipUserPrompt) {
            aiMessages.push({
                role: "user",
                content: tipUserPrompt
            });
        }

        if (userContent) {
            aiMessages.push({
                role: "user",
                content: userContent
            });
        }

        const requestdata = {
            messages: aiMessages,
            max_tokens: maxTokens,
            temperature: 0.7,
            frequency_penalty: 0,
            presence_penalty: 0,
            top_p: 0.95,
            stop: null,
            removeHtml: true
        };

        await this.openAiCall("/azureopenai/chatmessage", requestdata, tipElement, tipAiButton, tipAiRegenerateButton, tipAiCopyAnswerTextarea, tipAiCopyButton)
    }

    toggleDisableAiButton(aiButton) {
        if (aiButton) {
            aiButton.toggleAttribute("disabled");
            aiButton.classList.toggle("disabled");
        }
    }

    getAiAnswer(tipElement) {
        if (tipElement) {
            const aiAnswerEl = tipElement.querySelector(".tooltip__aichatcontent");

            if (aiAnswerEl && aiAnswerEl.textContent) {
                return aiAnswerEl.textContent.replace(/\"/g, "");
            }
        }
        return "";
    }

    copyAiAnswerToTextArea(e, toggle, tipElement) {
        e.stopPropagation();
        if (toggle && tipElement) {
            const userContentEl = toggle.parentElement.querySelector('textarea, input[type="text"]');
            const aiAnswer = this.getAiAnswer(tipElement);

            if (userContentEl && aiAnswer) {
                userContentEl.value = aiAnswer;
                userContentEl.dispatchEvent(new KeyboardEvent('keyup', { 'key': '' }));
                userContentEl.focus();
                window.buildFloatLabels();
                // hardcode validation for step 1 now because it is only used for step 1 currently.
                var formStepOne = $('#jsCompanyFormTab1');
                if (formStepOne) {
                    formStepOne.parsley().validate();
                }
            }
        }
       
    }

}

window.addEventListener('load', () => {
    // Find all tooltip toggles.
    const toggles = document.querySelectorAll('[data-tooltip-toggle]');

    // Init toggles
    if (toggles) {
        new Tooltip(toggles);
    }
});
