// Had to add parsley to the JS files seperately.
// Because they were called like this "form.parsley().validate()" and this fails with parsley is not a function.
import "parsleyjs/dist/parsley.min.js";
import "./companySearchAutoComplete.js";
import { Tooltip } from "./tooltip.js";
import { addCounterToEveryTextArea } from "./forms.js";

export function toggleButtons(enable, form) {
    var formButtons = form.find(".form-buttons");
    if (formButtons.length > 0) {
        formButtons.find(".btn").each(function () {
            var $button = $(this);
            if (enable) {
                $button.prop('disabled', false);
                $button.removeClass('disabled');
            }
            else {
                $button.prop('disabled', true);
                $button.addClass('disabled');
            }
        })

    }
}

export function toggleSpinner(show, form) {
    var spinnerEl = form.find(".form-element__spinner");
    if (spinnerEl.length > 0) {
        if (show) {
            spinnerEl.show();
        }
        else {
            spinnerEl.hide();
        }
    }
}

export function failed(form, result) {
    var status = result.status;
    var responseJSON = result.responseJSON;

    if (responseJSON && responseJSON.Deployment) {
        var deploymentMsg = form.find(".alert-deployment");
        if (deploymentMsg.length > 0) {
            deploymentMsg.show();
            setTimeout(function () {
                deploymentMsg.hide();
            }, 20000);
        }
    }
    else {
        if (status === 400) {
            var validationMsg = form.find(".alert-danger-validation");
            if (validationMsg.length > 0) {
                var errors = "";
                for (var key in responseJSON) {
                    if (responseJSON.hasOwnProperty(key)) {
                        var value = responseJSON[key];
                        errors += "<br>" + key + ": " + value;
                    }
                }
                
                var alertMsg = form.find(".alert-danger-validation-msg");
                var alertMsgHTML = alertMsg.html();

                var index = alertMsgHTML.indexOf('<br>'); // Find the index of the first <br> tag
                if (index !== -1) { // Check if <br> tag exists
                    alertMsgHTML = alertMsgHTML.substring(0, index); // Extract text before the <br> tag
                }
                
                var newAlertMsg = alertMsgHTML + errors;

                alertMsg.html(newAlertMsg);                
                validationMsg.show();                
            }
        }
        else {
            var dangerEl = form.find(".alert-danger-save");
            if (dangerEl.length > 0) {
                dangerEl.show();
                setTimeout(function () {
                    dangerEl.hide();
                }, 10000);
            }
        }
    }
}

export function saveForLater(formId) {
    var form = $('#' + formId);
    if (form.length > 0) {
        var dataForm = new FormData(form[0]);
        dataForm.append("ValidateData", false); //console.log('dataForm', dataForm.entries());

        $.ajax({
            url: form.attr("action"),
            type: 'POST',
            enctype: 'multipart/form-data',
            contentType: false,
            data: dataForm,
            processData: false,
            beforeSend: function () {
                // Disable buttons
                toggleButtons(false, form);
                // Show the spinner
                toggleSpinner(true, form);
            }
        })
            .done(function () {
                // Show the success message and hide it after 10 seconds.
                var successEl = form.find(".alert-success-save");
                if (successEl.length > 0) {
                    successEl.show();
                    setTimeout(function () {
                        successEl.hide();
                    }, 10000);
                }
            })
            .fail(function (result) {
                failed(form, result);
            })
            .always(function () {
                // Hide the spinner
                toggleSpinner(false, form);
                // Enable buttons
                toggleButtons(true, form);
            });
    }
}

export function goToStep(nextTabNbr) {
    var currentTabNbr = nextTabNbr - 1;
    var currentForm = $('#jsCompanyFormTab' + currentTabNbr);
    var nextForm = $('#jsCompanyFormTab' + nextTabNbr);
    var currentStep = $('#step' + currentTabNbr);
    var nextStep = $('#step' + nextTabNbr);
    var inactiveStepClass = 'form-progress__step';
    var activeStepClass = 'form-progress__step form-progress__step--active';
    var inactiveAttribute = 'aria-current';
    var validationMsg = currentForm.find(".alert-danger-validation");

    currentForm.parsley().validate();
    var isFormValid = currentForm.parsley().isValid();

    if (currentForm.length > 0 && isFormValid) {
        var dataForm = new FormData(currentForm[0]);
        dataForm.append("ValidateData", true);

        $.ajax({
            url: currentForm.attr("action"),
            type: 'POST',
            enctype: 'multipart/form-data',
            contentType: false,
            data: dataForm,
            processData: false,
            beforeSend: function () {
                // Disable buttons
                toggleButtons(false, currentForm);
                // Show the spinner
                toggleSpinner(true, currentForm);
            }
        })
            .done(function (result) {
                currentForm.hide();
                validationMsg.hide();
                if (nextForm.length > 0) {
                    // Is the next tab the preview?
                    if (nextTabNbr === 5) {
                        // A partial view was returned so we need to set the html
                        // console.log('html:', result);
                        var html = $('<div />').append(result);
                        //nextForm.html(result);
                        var homeTabContainer = nextForm.find('#home');
                        var homeData = html.find('.company-preview__company-page');
                        if (homeTabContainer.length > 0 && homeData.length > 0) {
                            homeTabContainer.html(homeData);
                        }
                        var cardTabContainer = nextForm.find('#profile');
                        var cardData = html.find('.company-preview__company-card')
                        if (cardTabContainer.length > 0 && cardData.length > 0) {
                            cardTabContainer.html(cardData);
                        }
                        var contactTabContainer = nextForm.find('#contact');
                        var contactData = html.find('.company-preview__company-slide')
                        if (contactTabContainer.length > 0 && contactData.length > 0) {
                            contactTabContainer.html(contactData);
                        }
                    }
                    nextForm.show();
                }
                if (currentStep.length > 0) {
                    currentStep.removeClass(activeStepClass);
                    currentStep.addClass(inactiveStepClass);
                    currentStep.removeAttr(inactiveAttribute);
                }
                if (nextStep.length > 0) {
                    nextStep.removeClass(inactiveStepClass);
                    nextStep.addClass(activeStepClass);
                    nextStep.attr("aria-current", "step");
                }
                // scroll to top after successful step change
                window.scroll(0, 0);
            })
            .fail(function (result) {
                failed(currentForm, result);
            })
            .always(function () {
                // Hide the spinner
                toggleSpinner(false, currentForm);
                // Enable buttons
                toggleButtons(true, currentForm);
                // Let's make sure that submit messages in Tab 5 are hidden by default.
                if (nextTabNbr === 5) {
                    $('#requestForPublishMsg').hide();
                    $('#requestForPublishErrorMsg').hide();
                }
            });
    } else if (!isFormValid) {
        var parsleyErrors = $('.parsley-errors-list.filled');
        if (parsleyErrors && parsleyErrors.length > 0) {
            parsleyErrors.get(0).scrollIntoView({ behavior: 'auto', block: 'center', inline: 'start' });
        }
    }
}

export function goToPreviousStep(previousTabNbr) {
    var currentTabNbr = previousTabNbr + 1;
    var currentForm = $('#jsCompanyFormTab' + currentTabNbr);
    var previousForm = $('#jsCompanyFormTab' + previousTabNbr);
    var currentStep = $('#step' + currentTabNbr);
    var previousStep = $('#step' + previousTabNbr);
    var inactiveAttribute = 'aria-current';
    var validationMsg = currentForm.find(".alert-danger-validation");

    if (currentForm.length > 0 && previousForm.length > 0) {
        var inactiveStepClass = 'form-progress__step';
        var activeStepClass = 'form-progress__step form-progress__step--active';
        currentForm.hide();
        validationMsg.hide();
        previousForm.show();
        if (currentStep.length > 0) {
            currentStep.removeClass(activeStepClass);
            currentStep.addClass(inactiveStepClass);
            currentStep.removeAttr(inactiveAttribute);
        }
        if (previousStep.length > 0) {
            previousStep.removeClass(inactiveStepClass);
            previousStep.addClass(activeStepClass);
            previousStep.attr("aria-current", "step");
        }
        // scroll to top when going to previous step
        window.scroll(0, 0);
        return false;
    }
}

$(function () {
    if (!$('.company-form-page').length) {
        return;
    }

    function split(val) {
        return val.split(/,\s*/);
    }
    function extractLast(term) {
        return split(term).pop();
    }

    // Add aria-current to step1
    $('#step1').attr("aria-current", "step");

    // Prevent parsley from adding parsley-error and parsley-success classes
    $('#jsCompanyFormTab1').parsley({
        errorClass: '',
        successClass: ''
    });
    $('#jsCompanyFormTab2').parsley({
        errorClass: '',
        successClass: ''
    });
    $('#jsCompanyFormTab3').parsley({
        errorClass: '',
        successClass: ''
    });
    $('#jsCompanyFormTab4').parsley({
        errorClass: '',
        successClass: ''
    });

    $('.js-keyword-autocomplete').each(function (index) {
        //console.log('auto complete ' + index);
        var $autocompleteEl = $(this);
        var storeName = $autocompleteEl.data("store"); //console.log('storeName', storeName);
        $.get("/KeywordApi/get?storeName=" + storeName)
            .done(function (data) {
                //console.log('data', data);
                var keywords = data.keywords.split(',');
                $autocompleteEl
                    .on("keydown", function (event) {
                        if (event.keyCode === $.ui.keyCode.TAB &&
                            $(this).autocomplete("instance").menu.active) {
                            event.preventDefault();
                        }
                    })
                    .autocomplete({
                        minLength: 1,
                        source: function (request, response) {
                            response($.ui.autocomplete.filter(
                                keywords, extractLast(request.term)));
                        },
                        focus: function () {
                            // prevent value inserted on focus
                            return false;
                        },
                        select: function (event, ui) {
                            var terms = split(this.value);
                            // remove the current input
                            terms.pop();
                            // add the selected item
                            terms.push(ui.item.value);
                            // add placeholder to get the comma-and-space at the end
                            terms.push("");
                            this.value = terms.join(", "); //console.log('terms: ', terms);
                            return false;
                        }
                    });
            })
            .fail(function () {
                return "";
            });
    });

    $('#goToStep2').on('click', function (e) {
        e.preventDefault();
        goToStep(2);
        return false;
    })

    $('#goToStep3').on('click', function (e) {
        e.preventDefault();
        goToStep(3);
        return false;
    })

    $('#goToStep4').on('click', function (e) {
        e.preventDefault();
        goToStep(4);
        return false;
    })

    $('#goToStep5').on('click', function (e) {
        e.preventDefault();
        goToStep(5);
        return false;
    })

    $('#requestPublish').on('click', function (e) {
        e.preventDefault();
        var form5Submit = $('#jsCompanyFormTab5Submit');
        var form5PreviewData = $('.company-preview__content-container');
        var $requestMsgEl = $('#requestForPublishMsg');
        var $errorMsgEl = $('#requestForPublishErrorMsg');
        if (form5Submit.length > 0) {
            var dataForm5 = new FormData(form5Submit[0]);

            $.ajax({
                url: form5Submit.attr("action"),
                type: 'POST',
                enctype: 'multipart/form-data',
                contentType: false,
                data: dataForm5,
                processData: false,
                beforeSend: function () {
                    // Disable buttons
                    toggleButtons(false, form5Submit);
                    // Show the spinner
                    toggleSpinner(true, form5Submit);
                    // Make sure error message is hidden
                    if ($errorMsgEl.length > 0) {
                        $errorMsgEl.hide();
                    }
                }
            })
                .done(function () {
                    form5Submit.hide();
                    form5PreviewData.hide();
                    if ($requestMsgEl.length > 0) {
                        $requestMsgEl.show();
                    }
                })
                .fail(function () {
                    if ($errorMsgEl.length > 0) {
                        $errorMsgEl.show();
                    }
                })
                .always(function () {
                    // Hide the spinner
                    toggleSpinner(false, form5Submit);
                    // Enable buttons
                    toggleButtons(true, form5Submit);
                });
        }
        return false;
    });

    $('#backToStep1').on('click', function (e) {
        e.preventDefault();
        goToPreviousStep(1);
        return false;
    })

    $('#backToStep2').on('click', function (e) {
        e.preventDefault();
        goToPreviousStep(2);
        return false;
    })

    $('#backToStep3').on('click', function (e) {
        e.preventDefault();
        goToPreviousStep(3);
        return false;
    })

    $('#backToStep4').on('click', function (e) {
        e.preventDefault();
        goToPreviousStep(4);
        return false;
    })

    $('#btnAddValueProposition').on('click', function (e) {
        e.preventDefault();
        var container = $('#valuePropositionContainer');
        var id = 'ValuePropositions-0';
        var $lastProposition = container.find('.form-element__textarea-container').last();
        if ($lastProposition.length > 0) {
            var $textarea = $lastProposition.find('textarea');
            if ($textarea.length > 0) {
                id = $textarea.attr('id') + "_1";
            }
        }

        // Have to do the tooltip content like this to preserve html tags for some reason. Without this they get escaped.
        var addedContainerId = "container_" + id;
        var tooltipContent = null;
        var aiTooltipContent = null;

        // using native JS to ensure the element is not added to the dom and is deleted afterwards.
        if (pageTranslations && (pageTranslations.tooltip || pageTranslations.aitooltip)) {
            var textareaEl = document.createElement("textarea");

            if (pageTranslations.tooltip) {
                textareaEl.innerHTML = pageTranslations.tooltip;
                tooltipContent = textareaEl.value;
            }
            if (pageTranslations.aitooltip) {
                textareaEl.innerHTML = pageTranslations.aitooltip;
                aiTooltipContent = textareaEl.value;
            }
            textareaEl.remove();
        }

        var contentToAdd = $(`<div class="form-element form-element-delete" id="${addedContainerId}">
            <div class="fl-wrap fl-wrap-textarea fl-is-required fl-is-active">
                <div class="form-element__textarea-container">
                    <label class="form-element__label fl-label" for="${id}">${pageTranslations.valueProposition}</label>
                    <textarea class="form-element__textarea fl-textarea pt-4" name="ValuePropositions" id="${id}" maxlength="250" rows="4" data-parsley-error-message="${pageTranslations.validationError.valueproposition}" data-parsley-trigger="focusout" placeholder="${pageTranslations.valueProposition}"></textarea>
                    <button type="button" aria-label="${pageTranslations.deleteValueProposition}" class="form-element__delete form-element__textarea-delete"></button>
                </div>
                ${tooltipContent ? tooltipContent : ""}
                ${aiTooltipContent ? aiTooltipContent: ""}
            </div>
        </div>`);

        container.append(contentToAdd);
        addCounterToEveryTextArea();
        window.buildFloatLabels();
        var containerJS = document.getElementById(addedContainerId);
        var addValueToggles = containerJS.querySelectorAll('[data-tooltip-toggle]');
        if (addValueToggles) {
            new Tooltip(addValueToggles);
        }
  
        return false;
    })

    $('#btnAddPerson').on('click', function (e) {
        e.preventDefault();
        var container = $('#addPersonBtnContainer');
        var count = 0;
        var lastPerson = $('.form-element-person').last();
        if (lastPerson.length > 0) {
            var lastIndex = lastPerson.find('.index');
            if (lastIndex.length > 0) {
                count = Number(lastIndex.val() + 1);
            }
        }
        var phonePattern = $("#CompanyForm-PhonePattern").val();
        var phoneNumberMaxLength = $("#CompanyForm-PhoneNumberMaxLength").val();

        var contentToAdd = $(`<div class="form-element-person form-element-delete">
        <div class="row">
            <div class="col-lg-7 form-element">
                <h4>${pageTranslations.contactPerson}</h4>
                <button type="button" aria-label="${pageTranslations.deleteContactPerson}" class="form-element__delete form-element__element-delete"></button>
            </div>
        </div>
        <input type="hidden" class="index" name="Persons.Index" value="${count}" />
        <div class="row">
            <div class="col-lg-7">
                <div class="form-element">
                    <div class="fl-wrap fl-wrap-input fl-is-active">
                        <div class="form-element__input-container">
                            <label class="form-element__label fl-label" for="Persons_${count}__FirstName">${pageTranslations.firstname}</label>
                            <input class="form-element__input fl-input" id="Persons_${count}__FirstName" name="Persons[${count}].FirstName" type="text" value="" placeholder="${pageTranslations.firstname}" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-7">
                <div class="form-element">
                <div class="fl-wrap fl-wrap-input fl-is-active">
                <div class="form-element__input-container">
                    <label class="form-element__label fl-label" for="Persons_${count}__LastName">${pageTranslations.lastname}</label>
                    <input class="form-element__input fl-input" id="Persons_${count}__LastName" name="Persons[${count}].LastName" type="text" value="" placeholder="${pageTranslations.lastname}" />
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-7">
                <div class="form-element">
                <div class="fl-wrap fl-wrap-input fl-is-active">
                <div class="form-element__input-container">
                <label class="form-element__label fl-label" for="Persons_${count}__Title">${pageTranslations.title}</label>
                <input class="form-element__input fl-input" id="Persons_${count}__Title" name="Persons[${count}].Title" type="text" value="" placeholder="${pageTranslations.title}" />
                </div>
                </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-7">
                <div class="form-element">
                <div class="fl-wrap fl-wrap-input fl-is-active">
                <div class="form-element__input-container">
                <label class="form-element__label fl-label" for="Persons_${count}__Phone">${pageTranslations.phone}</label>
                <input class="form-element__input fl-input" id="Persons_${count}__Phone" name="Persons[${count}].Phone" type="text" value="" placeholder="${pageTranslations.phone}" data-parsley-error-message="${pageTranslations.validationError.phone}" data-parsley-pattern="${phonePattern}" data-parsley-trigger="focusout" data-val="true" data-val-maxlength-max="${phoneNumberMaxLength}" maxlength="${phoneNumberMaxLength}" />
                </div>
                </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-7">
                <div class="form-element">
                    <div class="fl-wrap fl-wrap-input fl-is-active">
                        <div class="form-element__input-container">
                            <label class="form-element__label fl-label" for="Persons_${count}__Email">${pageTranslations.email}</label>
                            <input class="form-element__input fl-input" id="Persons_${count}__Email" name="Persons[${count}].Email" type="email" value="" placeholder="${pageTranslations.email}" data-parsley-error-message="${pageTranslations.validationError.email}" data-parsley-trigger="focusout" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>`);
        container.before(contentToAdd);
        window.buildFloatLabels();
        return false;
    })

    $('#saveForLater1').on('click', function (e) {
        e.preventDefault();
        saveForLater('jsCompanyFormTab1');
        return false;
    });

    $('#saveForLater2').on('click', function (e) {
        e.preventDefault();
        saveForLater('jsCompanyFormTab2');
        return false;
    });

    $('#saveForLater3').on('click', function (e) {
        e.preventDefault();
        saveForLater('jsCompanyFormTab3');
        return false;
    });

    $('#saveForLater4').on('click', function (e) {
        e.preventDefault();
        saveForLater('jsCompanyFormTab4');
        return false;
    });
})
